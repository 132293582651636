























































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator'
import Table from '@/components/Table/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import { getDicts } from '@/utils/dict'
import { Select, Option } from 'element-ui'
Vue.use(Select)
Vue.use(Option)

const transferVal = (data: any) => {
  const res = data.reduce((acc: { [value: string]: string }, cur: any) => {
    acc[cur.value] = cur.label
    return acc
  }, {}) as { [value: string]: string }
  return res
}

@Component({
  components: {
    Table,
    Pagination
  },
  filters: {
    stateFilter: (type: string) => {
      return transferVal([
        {
          value: '2',
          label: '待审核'
        },
        {
          value: '3',
          label: '未通过'
        },
        {
          value: '4',
          label: '已通过'
        }
      ])[type]
    }
  }
})
export default class TenantCheck extends Vue {
  $constants: any
  searchModel = {
    key: 'name',
    value: null
  }
  state = ''
  listLoading = false
  tableHeader = [
    {
      key: 'name',
      name: '姓名',
      width: '8%'
    },
    {
      key: 'mobile',
      name: '手机号',
      width: ''
    },
    {
      key: 'phase',
      name: '区域',
      width: '8%'
    },
    {
      key: 'buildingNo',
      name: '房屋',
      width: '8%'
    },
    {
      key: 'cardType',
      name: '证件类型',
      width: '10%',
      isSpecial: true
    },
    {
      key: 'cardNo',
      name: '证件号码',
      width: '16%'
    },
    {
      key: 'auditStatus',
      name: '审核状态',
      width: '10%',
      isSpecial: true
    },
    {
      key: 'submitTime',
      name: '提交时间',
      width: '14%'
    },
    {
      key: 'opt',
      name: '操作',
      width: '10%',
      isSpecial: true
    }
  ]
  tableData: any = []
  layout = 'total, sizes, prev, pager, next, jumper'
  total = 0
  listQuery = {
    page: 1,
    limit: 15
  }

  // 区域字典
  auditDicts: any = []
  //证件类型字典
  cardDicts: any = []

  async beforeCreate() {
    // 获取相关字典
    // 审核状态
    this.auditDicts = await getDicts('AUDIT_STATUS')
    // 证件类型
    this.cardDicts = await getDicts('CARD_TYPE')
  }
  created() {
    this.getTenantCheckList()
  }

  // 获取租客审核列表
  getTenantCheckList() {
    this.listLoading = true
    this.$api.resident
      .getAuditList({
        current: this.listQuery.page,
        size: this.listQuery.limit,
        auditStatus: this.state,
        auditType: 3,
        [this.searchModel.key]: this.searchModel.value
      })
      .then((res: any) => {
        this.listLoading = false
        const data = res.data
        if (data && data.success) {
          this.tableData = data.data
          this.total = data.total
        } else {
          this.$message.error(data.msg)
        }
      })
      .catch(() => {
        this.listLoading = false
      })
  }
  //查询
  searchFn() {
    this.listQuery.page = 1
    this.listQuery.limit = 15
    this.getTenantCheckList()
  }
  //审核状态
  stateFilter(type: string) {
    if (this.auditDicts && typeof type !== 'undefined') {
      const res = this.auditDicts.find((x: any) => x.value == type + '')
      return res && res.name
    }
  }
  //证件类型
  cardTypeFilter(type: number) {
    if (this.cardDicts && typeof type !== 'undefined') {
      const res = this.cardDicts.find((x: any) => x.value == type + '')
      return res && res.name
    }
  }
  toDetail(item: any) {
    this.$router.push('/residents/tenant-check-detail?id=' + item.id)
  }
}
